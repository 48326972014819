<!-- 应标资格详情 -->
<!--申请应标资格 -->
<template>
  <!-- v-loading="loading" -->
  <div>
    <back />
    <div class="bidEligibility">
      <!-- 基本信息 -->
      <div class="detail">
        <header>应标资格</header>
        <!--1 独立标资格 -->
        <div class="form" v-if="this.basicInfo.eligibleType == 2">
          <el-form label-width="120px" :model="basicInfo">
            <el-row>
              <el-col :span="8">
                <el-form-item label="应标金额">
                  <el-input v-model="basicInfo.eligiblePrice" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="应标资格类型">
                  <div class="eligiblePrice">独立标资格</div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="标号">
                  <el-input v-model="basicInfo.tendersLabel" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="16">
                <el-form-item label="标书名称">
                  <el-input v-model="basicInfo.tendersName" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="货车数量">
                  <el-input v-model="basicInfo.truckNumber" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="开标时间">
                  <el-input v-model="basicInfo.tendersTime" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="缴费状态">
                  <div :class="basicInfo.payStatus == 0 ? 'paymentStatus' : 'eligiblePrice'">{{ basicInfo.payStatus == 0
                    ?
                    '未缴费' : '已缴费' }}</div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="审核状态">
                  <div :class="basicInfo.status == 0 ? 'paymentStatus' : 'eligiblePrice'">{{ basicInfo.status == 0 ?
                    '待审核' : basicInfo.status == 1 ? '审核通过' : basicInfo.status == 2 ? '审核不通过' : '-' }}</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="basicInfo.payStatus == 1">
              <el-col :span="8">
                <el-form-item label="缴费时间">
                  <el-input v-model="basicInfo.payTime" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="basicInfo.status == 1 || basicInfo.status == 2">
                <el-form-item label="审核时间">
                  <el-input v-model="basicInfo.auditTime" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="合作院校">
                  <el-input v-model="basicInfo.cooperateCollege" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="配送区域">
                  <el-input v-model="basicInfo.deliveryArea" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <!--2 平台标资格 -->
        <div class="form2" v-else>
          <el-form label-width="120px" :model="basicInfo">
            <el-row>
              <el-col :span="8">
                <el-form-item label="应标金额">
                  <el-input v-model="basicInfo.eligiblePrice" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="应标资格类型">
                  <!-- <div class="eligiblePrice">{{ basicInfo.eligiblePrice == 2 ? '平台资格' : '独立标资格' }}</div> -->
                  <div class="eligiblePrice">平台资格</div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="货车数量" prop="truckNumber">
                  <el-input v-model="basicInfo.truckNumber" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="缴费状态">
                  <div :class="basicInfo.payStatus == 0 ? 'paymentStatus' : 'eligiblePrice'">
                    {{ basicInfo.payStatus == 0 ? '未缴费' : '已缴费' }}
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="审核状态">
                  <div :class="basicInfo.payStatus == 0 ? 'paymentStatus' : 'eligiblePrice'">
                    {{ basicInfo.status == 0 ? '待审核' : basicInfo.status == 1 ? '审核通过' : basicInfo.status == 2 ? '审核不通过'
                      : '-' }}
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="可投商品次数">
                  <el-input v-model="basicInfo.tendersCount" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="应标资格有效期">
                  <el-input v-model="basicInfo.validDate" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="basicInfo.payStatus == 1">
              <el-col :span="8">
                <el-form-item label="缴费时间">
                  <el-input v-model="basicInfo.payTime" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="basicInfo.status == 1 || basicInfo.status == 2">
                <el-form-item label="审核时间">
                  <el-input v-model="basicInfo.auditTime" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="合作院校">
                  <el-input v-model="basicInfo.cooperateCollege" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="配送区域">
                  <el-input v-model="basicInfo.deliveryArea" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="basicInfo.status == 2">
              <el-col>
                <el-form-item label="不通过理由">
                  <div class="paymentStatus">{{ basicInfo.remark }}</div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- 审核通过并且已缴费 -->
      <!-- <div v-if="(basicInfo.status == 1 && basicInfo.payStatus == 1)"> -->
      <div class="Btn" v-if="basicInfo.payStatus == 0">
        <!-- 未缴费并且没审核状态 -->
        <el-button type="primary" @click="handlePay">去缴费</el-button>
      </div>
      <!-- 手机验证码 -->
      <child :showFlag="showFlag" @closeChildDialog="closeChildDialog"></child>
    </div>

  </div>
</template>

<script>
import child from './../detail/paymentDet.vue'
export default {
  data() {
    return {
      loading: true,
      showFlag: false,//缴费组件显隐
      basicInfo: {},//基本信息
      biddingId: '',//主键id
    }
  },
  components: {
    child,
  },
  created() {
    // console.log(this.$route.query.biddingId);
    // console.log('resBidManDet', JSON.parse(localStorage.getItem('resBidManDet')));
    this.biddingId = this.$route.query.biddingId
    this.getRequest()
  },
  methods: {
    // 请求
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.sendBiddingDetail, {
        params: {
          biddingId: this.biddingId
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          // console.log('result', result.result);
          this.basicInfo = result.result
          this.basicInfo.tendersCount = this.basicInfo.tendersCount == -1 ? '不限' : this.basicInfo.tendersCount
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },
    // 检查用户当前支付平台绑定状态
    handlePay() {
      this.$store.commit('setPayQuery', null)
      // 缓存当前行，用于获取验证码
      let query = {
        sourceId: this.biddingId, // 对应平台的主键id
        fee: this.basicInfo.eligiblePrice, // 缴费金额
        supplierId: this.$store.state.userInfo.supplierId, //供应商id
        sourceType: this.basicInfo.eligibleType, // 支付来源：1平台应标资格；2独立标应标资格；3保证金
      }
      this.$store.commit('setPayQuery', query)
      this.$axios.post(this.$api.select_token, {
        phoneNum: this.$store.state.userInfo.userAccount, // 手机号
        supplierId: this.$store.state.userInfo.supplierId, // 供应商id
      }).then((res) => {
        if (res.data.code == 100) {
          // result为空时该供应商没有绑定第三方支付平台
          if (res.data.result == null) {
            this.showFlag = true
          } else {
            this.$router.replace({ name: 'paymentPage' })
          }
        }
      });
    },
    //  接受子组件调用的关闭弹出框方法
    closeChildDialog() {
      this.showFlag = false
    },
  },
}
</script>

<style scoped lang='scss'>
.bidEligibility {
  padding: 0 30px;

  .detail {
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 20px;


    header {
      height: 45px;
      border-radius: 6px 6px 0px 0px;
      padding-left: 30px;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 16px;
      color: #1A1A1A;
      line-height: 45px;
    }

    .eligiblePrice {
      color: #606266;
      padding: 0px 10px 0px 10px;
      background: #f2f2f2;
      border-radius: 10px;
    }

    .paymentStatus {
      color: red;
      padding: 0px 10px 0px 10px;
      background: #f2f2f2;
      border-radius: 10px;
    }

    .form {
      padding: 20px 20px 0px 10px;
    }

    .form2 {
      padding: 20px 20px 0px 10px;
    }

  }

  .Btn {
    text-align: center;
  }


  .captcha_row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 550px;

    .send {
      margin-left: 30px;
    }

  }

}
</style>